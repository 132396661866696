import { AppBar, Box, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { ReactComponent as ChocolateBoxIcon } from '../../Assets/images/ChocolateBoxIcon.svg'
import { headerCard } from '../../Styles/Header/HeaderStyles'
import moment from 'moment'

const Header = (props) => {
    const { documentDetails } = props

    const theme = useTheme()

    return (
        <AppBar
            elevation={0}
            component="nav"
            position="sticky"
            sx={{
                m: -1,
                width: '100vw',
                height: '48px',
                boxShadow: `0px 4px 6px #d2dae2`,          //currentMode.mode === 'dark' ? '#545454' : '#d2dae2'
                background: '#fff',                        //currentMode.mode === 'dark' ? theme.palette.nhance.dark : theme.palette.nhance.light,
                zIndex: theme.zIndex.drawer + 1,
            }}
        >
            <Box sx={headerCard?.header}>
                <Grid container>
                    <Grid item xs={3} md={6} sx={headerCard?.headerGrid}>
                        <Box sx={headerCard?.box}>
                            <ChocolateBoxIcon />
                            <img
                                src={`https://twin-development.s3.ap-south-1.amazonaws.com/$2b$10$k21hF0spByIZ.qzh6z2wYO.svg`}
                                alt="logo"
                                height="25px"
                                style={{
                                    marginLeft: 20,
                                    paddingRight: 20,
                                    // borderRight: buildings.selectedBuilding ? `1px solid ${color}` : 'none',
                                    borderRight: '1px solid black',
                                    cursor: 'pointer',
                                }}
                            // onClick={() => history.push('/')}
                            />

                            <Typography
                                // color={color}
                                color={'#282a2e'}
                                component={'span'}
                                ml={2}
                                fontWeight={700}
                            >
                                Document Hub
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={9} md={6} sx={headerCard?.sentDetails} columnGap={2}>
                        {
                            (documentDetails?.validUntil && documentDetails?.email) && <Box mr={2}>
                                <Typography sx={headerCard?.sentDetailsTypography} color={'#282a2e'}>
                                    Shared with: {documentDetails?.email}
                                </Typography>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Box>
        </AppBar>
    )
}

export default Header